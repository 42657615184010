import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useHistory } from "react-router";
import SignInForm from "../components/auth/SignInForm";
import useSignIn from "../hooks/useSignIn";

const SignIn: FC<{}> = () => {
  const snackbar = useSnackbar();
  const history = useHistory();

  const signIn = useSignIn({
    onSuccess: () => {
      history.push("/app");
    },
    onError: ({ error }) => {
      if (error.includes('Revisa tu correo')) {
        history.push("/email-confirmation");
      }
      snackbar.enqueueSnackbar(error, { variant: "error" });
    },
  });

  return (
    <>
      <Typography
        variant="body1"
        component="h1"
        sx={{ color: (theme) => theme.palette.text.disabled, mb: 2 }}
      >
        Bienvenido, inicia sesión con tu cuenta
      </Typography>
      <SignInForm onSubmit={signIn.mutate} submitting={signIn.isLoading} />
    </>
  );
};

export default SignIn;
