/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, FC, useCallback, useContext, useState } from "react";
import { SignInResponse } from "../types/auth";

export type UserContextState = {
  user: SignInResponse | null;
  update: (user: SignInResponse | null) => void;
  userEmail?: string;
  setUserEmail?: (email: string) => void;
  userPassword?: string;
  setUserPassword?: (password: string) => void;
};

export const userContext = createContext<UserContextState>(
  {} as UserContextState
);

export const UserContextProvider: FC<{}> = ({ children }) => {
  /** Checar si está en el localStorage para validar el usuario al hacer refresh */
  const isAuthenticated = localStorage.getItem('user') ? true : false;
  const authUser = localStorage.getItem('user') || "";
  const [user, setUser] = useState<SignInResponse | null>(isAuthenticated ? JSON.parse(authUser) : null);
  
  /** Info del usuario para validar código de autenticación del dispositivo */
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const update = useCallback((user: SignInResponse | null) => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
    setUser(user);
  }, []);

  return (
    <userContext.Provider value={{ user, update, userEmail, setUserEmail, userPassword, setUserPassword }}>
      {children}
    </userContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext<UserContextState>(userContext);
};
