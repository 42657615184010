import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { SignInPayload } from "../../types/auth";
import BusyButton from "../shared/BusyButton";
import { useUserContext } from '../../providers/UserContextProvider';

/**
 * Validaciones que se aplicarán a los campos del formulario
 */
const signInSchema = Yup.object().shape({
  email: Yup.string().required("*Requerido").email("Ingrese un email válido"),
  password: Yup.string()
    .required("*Requerido")
    .min(8, ({ min }) => `Ingrese por lo menos ${min} caracteres`),
});

/**
 * Valores que recibirá el componente por **Props**
 */
type Props = {
  onSubmit: (payload: SignInPayload) => void;
  submitting: boolean;
};

/**
 * Componente para inciar sesión
 * @param onSubmit - Función para enviar los valores para iniciar sesión
 * @param submitting - Bandera para indicar que se ha enviado el formulario
 */
const SignInForm: FC<Props> = ({ onSubmit, submitting }) => {

  /** Información que se guarda en el UserContext para validar dispositivo */
  const { setUserEmail, setUserPassword } = useUserContext();

  const {
    handleBlur,
    handleSubmit,
    touched,
    errors,
    values,
    handleChange,
    isValid,
  } = useFormik<SignInPayload>({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: signInSchema,
    onSubmit: (values) => {
      setUserEmail!(values.email);
      setUserPassword!(values.password);
      onSubmit(values);
    },
  });


  return (
    <Grid
      container
      component="form"
      direction="column"
      spacing={2}
      onSubmit={handleSubmit}
    >
      <Grid item>
        <TextField
          name="email"
          label="Correo electrónico"
          variant="standard"
          onChange={handleChange("email")}
          value={values.email}
          fullWidth
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          onBlur={handleBlur("email")}
        />
      </Grid>
      <Grid item>
        <TextField
          name="password"
          label="Contraseña"
          type="password"
          variant="standard"
          onChange={handleChange("password")}
          value={values.password}
          fullWidth
          error={touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          onBlur={handleBlur("password")}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="end"
        alignItems="center"
      >
        <Button component={Link} to="/recover-password">
          Olvidé mi contraseña
        </Button>
      </Grid>
      <Grid item container justifyContent="center">
        <BusyButton
          component={Button}
          variant="contained"
          type="submit"
          sx={{ backgroundColor: "#43425D", px: 4 }}
          disabled={submitting || !isValid}
          loading={submitting}
        >
          Iniciar sesión
        </BusyButton>
      </Grid>
    </Grid>
  ) 
};

export default SignInForm;
