import { ButtonProps, CircularProgress } from "@mui/material";
import React, { ElementType, FC } from "react";

/**
 * Componente de botón personalizado
 * @param component - Componente a renderizar
 * @param loading - Bandera para indicar que se esta realizando una acción
 */
const BusyButton: FC<
  ButtonProps & { component: ElementType; loading: boolean }
> = ({ children, component, loading, ...props }) => {
  let Component = component;

  if (!component) {
    throw new Error(
      "BusyButton requires a component to render the root of the component"
    );
  }

  return (
    <Component {...props}>
      {loading ? <CircularProgress size={25} color="inherit" /> : children}
    </Component>
  );
};

export default BusyButton;
