import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { SnackbarProvider } from "notistack";
import { FC, StrictMode } from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { queryClient } from "../utils/query";
import theme from "../utils/theme";
import { UserContextProvider } from "./UserContextProvider";

const AppProviders: FC<{}> = ({ children }) => {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <CssBaseline />
            <BrowserRouter basename={process.env.REACT_APP_HOMEPAGE}>
              <UserContextProvider>{children}</UserContextProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
};

export default AppProviders;
