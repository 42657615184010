import ReactDOM from "react-dom";
import App from "./App";
import AppProviders from "./providers/AppProviders";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
);

reportWebVitals();
