import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import React from "react";
import background from "../../assets/background.png";
import Logo from "./Logo";

const PageLayout = React.memo(({ children }) => (
  <Grid container>
    <Box
      component={Grid}
      item
      xs={6}
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: { xs: 'none', md: 'block', sm: "none", lg: "block" }
      }}
    />
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      xs={6}
      sx={{
        width: "100%",
        minHeight: "100vh",
        maxWidth: 350,
        margin: "auto",
      }}
    >
      <Logo />
      {children}
    </Grid>
  </Grid>
));

export default PageLayout;
