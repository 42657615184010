import { AxiosResponse } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { signInAPI } from "../api/auth";
import { useUserContext } from "../providers/UserContextProvider";
import { SignInErrorResponse, SignInPayload } from "../types/auth";

/**
 * Hook para iniciar sesión
 * @param SignInPayload - Información para iniciar seción
 * @returns SignInResponse | SignInErrorResponse
 */
const useSignIn = (
  options?: UseMutationOptions<void, SignInErrorResponse, SignInPayload>
) => {
  const { update } = useUserContext();

  return useMutation(
    ["signIn"],
    async (data) => {
      try {
        const response = await signInAPI(data);
        update(response);
      } catch (error) {
        throw (error as AxiosResponse).data;
      }
    },
    options
  );
};

export default useSignIn;
