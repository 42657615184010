import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import LoaderFallback from "./components/shared/LoaderFallback";
import PageLayout from "./components/shared/PageLayout";
import SignIn from "./screens/SignIn";
import moment from "moment";
import "moment/locale/es-mx";

moment.locale("es-mx");

const AppLayout = React.lazy(() => import("./screens/AppLayout"));
const EmailAuth = React.lazy(() => import("./screens/EmailAuth"));
const RecoverPassword = React.lazy(() => import("./screens/RecoverPassword"));
const UpdatePassword = React.lazy(() => import("./screens/UpdatePassword"));
const ChangePassword = React.lazy(() => import("./screens/ChangePassword"));

const App = () => {
  return (
    <Suspense fallback={<LoaderFallback />}>
      <Switch>
        <Route path="/app" component={AppLayout} />
        <Route path="/">
          <PageLayout>
            <Route path="/email-confirmation" component={EmailAuth} />
            <Route path="/update-password" component={UpdatePassword} />
            <Route path="/recover-password" component={RecoverPassword} />
            <Route path="/change-password" component={ChangePassword} />
            <Route exact path="/" component={SignIn} />
          </PageLayout>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default App;
