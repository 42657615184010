import { styled } from "@mui/system";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-linq.png";

/**
 * Estilo personalizado para el componente **img**
 * renombrado como **Image**
 */
const Image = styled("img")({
  width: "auto",
});

/**
 * Valores que recibirá el componente por **Props**
 */
type Props = {
  size?: "small" | "normal";
  redirect?: boolean
};

/**
 * Componente que muestra el logo de la aplicación
 * @param size - Para indicar el tamaño del logo
 * @param redirect - Para indicar si actua como link para redireccionar al dashboard o no
 */
const Logo: FC<Props> = ({ size = "normal", redirect = false }) => (
  redirect ?
  <Link to="/app">
    <Image
      src={logo}
      alt="Logo"
      sx={{
        height: size === "normal" ? "75px" : "60px",
      }}
    />
  </Link>
  :
  <Image
    src={logo}
    alt="Logo"
    sx={{
      height: size === "normal" ? "75px" : "60px",
    }}
  />
);

export default React.memo(Logo, (prev, next) => prev.size === next.size);
