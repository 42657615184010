import {
  OneTimePassword,
  SignInPayload,
  SignInResponse,
  UpdatePasswordPayload,
} from "../types/auth";
import axiosInstance from "../utils/axios";

/**
 * Servicio para iniciar sesión en la plataforma
 * @param payload - Objeto que contiene correo y contraseña del usuario
 * @returns Información de la sesión
 */
export const signInAPI = async (payload: SignInPayload) => {
  const data = new FormData();
  data.append("email", payload.email);
  data.append("password", payload.password);

  const response = await axiosInstance.request<SignInResponse>({
    url: "/loginWeb",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
  });

  return response.data;
}

/**
 * Servicio para iniciar sesión en la plataforma
 * @param payload - Objeto que contiene correo y contraseña del usuario
 * @returns Información de la sesión
 */
export const oneTimePasswordAPI = async (payload: OneTimePassword) => {
  const data = new FormData();
  data.append("email", payload.email+"");
  data.append("password", payload.password+"");
  data.append("one_time_password", payload.oneTimePassword);

  const response = await axiosInstance.request<SignInResponse>({
    url: "/one-time-password",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
  });

  return response.data;
};

/**
 * Servicio para cerrar la sesión en la plataforma
 * @param token
 */
export const signOutAPI = async (token: string) => {
  return await axiosInstance.request({
    url: "/logout",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/**
 * Servicio que envia la liga para recuperar la contraseña
 * @param email - Correo al que se le enviará la liga para recuperar la contaseña
 */
export const recoverPasswordAPI = async (email: string) => {
  const data = new FormData();
  data.append("email", email);

  const response = await axiosInstance.request({
    url: "/recover-password",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
  });

  return response.data;
};

/**
 * Servicio para actualizar la contraseña
 * @param payload - Objeto que contiene la información necesaria para cambiar la contraseña (UpdatePasswordPayload)
 */
export const updatePasswordAPI = async (payload: UpdatePasswordPayload) => {

  const isChange = payload.newPassword !== "" ? true : false;
  const user = JSON.parse(localStorage.getItem('user') || "{}");

  const data = new FormData();
  if (!isChange) {
    data.append("passwordToken", payload.passwordToken || "");
  }
  data.append("email", payload.email);
  data.append("password", payload.password);
  data.append("newPassword", payload.newPassword || "");
  data.append("password_confirmation", payload.password_confirmation);


  const response = await axiosInstance.request({
    url: isChange ? "/change-password" : "/update-password",
    method: "POST",
    data,
    headers: isChange ? {
      Authorization: `Bearer ${user.access_token}`,
    } : {
      "Content-Type": "multipart/form-data"
    },
    responseType: "json",
  });

  return response.data;
};
