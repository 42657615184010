import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";

/**
 * Valores que recibirá el componente por **Props**
 */
type Props = {
  fullscreen?: boolean;
};

/**
 * Componente que muestra un loading
 * @param fullscreen - Bandera para indicar si el componente 
 * se mostrará en pantalla completa
 */
const LoaderFallback: FC<Props> = ({ fullscreen = true }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: fullscreen ? "100vh" : "100%",
        width: "100%",
      }}
    >
      <CircularProgress size={25} />
    </Box>
  );
};

export default React.memo(LoaderFallback);
