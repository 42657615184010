import { createTheme, responsiveFontSizes } from "@mui/material";

/**
 * Definición de la paleta de colores principales y tipografia general de la aplicación
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#262262",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#B0D357",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF0000",
      light: "#F58484",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFCE00",
    },
    info: {
      main: "#17AFBC",
      dark: "#3B86FF",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#388E3C",
      light: "#B0D356",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: '"Akzidenz Grotesk", "Open Sans", sans-serif',
    fontSize: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: `
          text-transform: none
        `,
      },
    },
  },
});

export default responsiveFontSizes(theme);
