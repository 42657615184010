import axios from "axios";

/**
 * Instancia de **Axios** que nos ayuda a concatenar el endpoint del backend
 */
axios.defaults.baseURL = process.env.REACT_APP_API_ENTPOINT;

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response &&
      (response.status === 401 || response.data.message === 'Unauthenticated.') &&
      !response.data?.error?.includes('Contraseña o correo incorrecto') &&
      !response.data?.error?.includes('Revisa tu correo') &&
      !response.data?.error?.includes('Codigo incorrecto') &&
      !response.data?.error?.includes('expirada')
    ) {
      localStorage.removeItem("user");
      return window.location.href = '/';
    }

    return Promise.reject(error.response)

  });

export default axiosInstance;
